<template>
	<div>
		<a-modal title="快速创建小程序" :width="600" :visible="visible" @cancel="handleCancel" @ok="onSubmit" :loading="confirmLoading">

			<a-spin :spinning="loading">
				<div>
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item label="企业名称">
							 <a-input style="width: 450px;" v-model="form.name"  placeholder="请输入企业名称" />
						</a-form-model-item>
						<a-form-model-item label="证件类型">
							 	  <a-select style="width: 200px" v-model="form.code_type">
							 	      <a-select-option :value="1">
							 	        统一社会信用代码
							 	      </a-select-option>
							 	      <a-select-option :value="2">
							 	        组织机构代码
							 	      </a-select-option>
							 	      <a-select-option :value="3">
							 	       营业执照注册号
							 	      </a-select-option>
							 	    </a-select>
						</a-form-model-item>
						<a-form-model-item label="证件编码">
							 <a-input style="width: 450px;" v-model="form.code"  placeholder="请输入证件编码" />
						</a-form-model-item>
						<a-form-model-item label="法人微信号">
							 <a-input style="width: 450px;" v-model="form.legal_persona_wechat"  placeholder="请输入法人微信号" />
						</a-form-model-item>
						<a-form-model-item label="法人真实姓名">
							 <a-input style="width: 450px;" v-model="form.legal_persona_name"  placeholder="请输入法人真实姓名" />
						</a-form-model-item>

					</a-form-model>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
				form: {
					name:'',
					code:'',
					code_type:1,
					legal_persona_wechat:'',
					legal_persona_name:'',
				}
			}
		},
		methods: {
			handleCancel() {
				this.$emit('cancel');
			},
			onSubmit() {
				if(this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('platform/admin/savePassageway',{
					name:this.form.name,
					code:this.form.code,
					code_type:this.form.code_type,
					legal_persona_wechat:this.form.legal_persona_wechat,
					legal_persona_name:this.form.legal_persona_name,
				}).then(res=>{
					this.confirmLoading=false;
					this.$emit('ok');
				}).catch(res=>{
					this.confirmLoading = false;
				});
			}
		}
	}
</script>

<style>
</style>
