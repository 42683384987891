<template>
	<div>
		<div >
			<div class="form-search-box">
				<a-form layout="inline" >
					<a-form-item label="关键字">
						<a-input v-model="search.keyword" placeholder="请输入关键字"></a-input>
					</a-form-item>
		
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>
		
		<div class="mt20 bg-w">
			<div class="form-search-box">
				<a-button type="primary" @click="showFree = true" icon="plus">申请免认证</a-button>
			</div>
			<div class="wxb-table-white mt10">
				<a-table rowKey="free_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
				</a-table>
			</div>
		</div>
		
		<add-free :visible="showFree" @cancel="cancelFree" @ok="okFree"></add-free>
		
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import addFree from './components/addFree.vue';
	export default{
		mixins: [listMixin],
		components:{
			addFree
		},
		data(){
			return {
				search:{
					keyword:'',
				},
				showFree:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: 'ID编号',dataIndex: 'free_id',align: 'center',ellipsis: true},
					{title: '公司名称',dataIndex: 'name',align: 'center'},
					{title: '证件类型',dataIndex: 'code_type_means',align: 'center'},
					{title: '证件号码',dataIndex: 'code',align: 'center'},
					{title: '法人姓名',dataIndex: 'legal_persona_name',align: 'center'},
					{title: '法人微信',dataIndex: 'legal_persona_wechat',align: 'center'},
					{title: '申请状态',dataIndex: 'status_means',align: 'center'},
					{title: '其他',dataIndex: 'message',width:300, align: 'center',ellipsis: true},
					{title: '申请时间',dataIndex: 'add_time_format', align: 'center'},
				],
				datas:[],
			}
		},
		methods:{
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			cancelFree(){
				this.showFree = false;
			},
			okFree(){
				this.showFree = false;
				this.$message.success('申请成功');
				this.getLists();
			},
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getPassagewayList',{
					limit:this.pageSize,
					page:this.page,
					keyword:this.search.keyword,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
		}
	}
</script>

<style>
	
</style>